import { Link } from "react-router-dom"

export default function NavBar(){
    return(
        <div className="container p-4 text-center mt-3">
            <div className="row">
                <div className="col col-3">
                    <Link to={'/'}>
                        Home
                    </Link>
                </div>
                <div className="col col-3">
                    <Link to={'/games'}>
                        Gérer les jeux
                    </Link>
                </div>
                <div className="col col-3">
                    <Link to={'/gains'}>
                        Gérer les gains
                    </Link>
                </div>
                <div className="col col-3">
                    <Link to={'/datas'}>
                        Datas
                    </Link>
                </div>
            </div>
        
        </div>
    )
}