import { useEffect, useState } from "react";
import { GetUserInfos } from "../../utils/requests/Users";

export default function NeedConnected(){
    
    const [userConnected, setUserConnected] = useState(null);
    
    const verifyUserConnected = async () => {
        const res = await GetUserInfos();
        if(res.success){
            setUserConnected(true);
        }else{
            setUserConnected(false);
        }
    }

    useEffect(()=>{
        verifyUserConnected();
    }, [])

    if(userConnected === false){
        window.location.href = '/login';
    }

    if(!userConnected){
        return null;
    }

    return(
        <></>
    );
}