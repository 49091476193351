import { getUserCookie } from "../Cookies/Users";
import requestApi from "./request_api";

export async function getGames(){
    const res = await requestApi('/getGames', 'post', {
        user_token: getUserCookie(),
    });

    return res.data;
}

export async function createGame(name){
    const res = await requestApi('/createGame', 'post', {
        user_token: getUserCookie(),
        input: {
            name: name,
        }
    });

    return res.data;
}

export async function updateGame(name, default_gain_id, game_id, algo, ratio){
    const res = await requestApi('/updateGame', 'post', {
        user_token: getUserCookie(),
        input: {
            name: name,
            algo_type: algo,
            default_gain_id: default_gain_id,
            game_id: game_id,
            ratio: ratio,
        }
    });

    return res.data;
}

export async function deleteGame(game_id){
    const res = await requestApi('/deleteGame', 'post', {
        user_token: getUserCookie(),
        input:{
            game_id: game_id,
        }
    });

    return res.data;
}

export async function getGame(game_id){
    const res = await requestApi('/getGame', 'post', {
        user_token: getUserCookie(),
        input: {
            game_id: game_id,
        }
    });

    return res.data;
}

export async function removePlayersForGame(game_id){
    const res = await requestApi('/removePlayersForGame', 'post', {
        user_token: getUserCookie(),
        input: {
            game_id: game_id,
        }
    });

    return res.data;
}

export async function getAllTime(){
    const res = await requestApi('/getAllTime', 'post', {
        user_token: getUserCookie(),
    });

    return res.data;
}

export async function getDatas(game_id){
    const res = await requestApi ('/getDatas', 'post', {
        user_token: getUserCookie(),
        input:{
            game_id
        },
    });

    return res.data;
}