import { useEffect, useState } from "react"
import { updateGame } from "../../../utils/requests/Games"
import { getGains } from "../../../utils/requests/Gains";

export default function EditGame({game}){
    
    const [gameName, setGameName] = useState(game.name);
    const [gainId, setGainId] = useState(game.default_gain_id);
    const [gains, setGains] = useState([]);
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState('');

    const submit = async (e) => {
        e.preventDefault();

        const res = await updateGame(gameName, gainId, game.id, game.algo_type, game.ratio);
        if(!res.success){
            setMsg(res.msg);
        }
    }
    
    const loadGains = async () => {
        const res = await getGains(game.id);
        if(res.success){
            setGains(res.data);
        }
    }
    useEffect(()=>{
        loadGains();
        console.log(gains);
        setTimeout(()=>{
            setLoading(false);
        }, 100);
        console.log(gainId);
    }, []);

    if(loading){
        return(
            <div className="text-center">
                <h3>Chargement</h3>
            </div>
        )
    }

    else{
        return(
            <form onSubmit={submit}>
            <div className="row">
            <div className="col col-2 mt-3 mb-3 c-hover">
            <span className="game-name" onClick={()=>{window.location.href = '/game/'+game.id}}>
            {gameName}
            </span>
            </div>
            <div className="col col-5 mt-3 mb-3">
            <span className="game-id">
            {game.id}
            </span>
            </div>
            <div className="col col-3 mt-3 mb-3">
            <select value={gainId} className="form-control" onChange={(e)=>{setGainId(e.target.value)}}>
                {gains.map((gain)=>{
                    return (
                        <option key={gain.id} value={gain.id}>{gain.name}</option>
                    )
                })}
            </select>
            </div>
            <div className="col col-2 mt-3 mb-3">
            <button className="form-control">
                Modifier
            </button>
            </div>
            <span className="err">{msg}</span>
            </div>
            </form>
            )
    }
}