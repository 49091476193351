import NavBar from "../../NavBar/NavBar";
import NeedConnected from "../../NeedConnected/NeedConnected";
import Graph from "./Gaph";

export default function Home(){
    return(
        <>
        <NeedConnected></NeedConnected>
            <NavBar/>
            <div className="container p-4 mt-5">
                <div className="row">
                    <Graph/>
                </div>
            </div>
        </>
    )
}