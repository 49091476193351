import { useState, useEffect } from "react";
import { GetUserInfos, login } from "../../../utils/requests/Users";

export default function Login(){
    const [userConnected, setUserConnected] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [err, setErr] = useState('');
    const [disabled, setDisabled] = useState(false);
    
    const verifyUserConnected = async () => {
        const res = await GetUserInfos();
        if(res.success){
            setUserConnected(true);
        }else{
            setUserConnected(false);
        }
    }

    const submit = async(e)=>{
        e.preventDefault();
        setDisabled(true);
        const res = await login(email, password);
        if(res.success){
            window.location.href = '/'
        }else{
            setErr('Email ou mot de passe incorrect');
        }
        setDisabled(false)
    }

    useEffect(()=>{
        verifyUserConnected();
    }, []);

    if(userConnected){
        window.location.href = '/';
    }
    
    return(
        <div className="position-absolute top-50 start-50 translate-middle form-connexion p-3">
            <form onSubmit={submit}>
                <h1 className="text-center">
                    Connexion
                </h1>
                <input type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} required className="form-control" />
                <input type="password" value={password} onChange={(e)=>{setPassword(e.target.value)}} required className="form-control mt-3" />
                <button type="submit" className="form-control mt-3" disabled={disabled}>Connexion</button>
                <span className="err">{err}</span>
            </form>
        </div>
    )
}