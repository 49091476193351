import {BrowserRouter, Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Login from './components/Pages/Login/Login';
import Register from './components/Pages/Register/Register';
import Home from './components/Pages/Home/Home';
import Games from './components/Pages/Games/Games';
import Gains from './components/Pages/Gains/Gains';
import Datas from './components/Pages/Datas/Datas';
import { EditGain } from './components/Pages/Gains/EditGain';
import PageEditGame from './components/Pages/Games/PageEditGame';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' exact Component={Login}/>
        <Route path='/register' exact Component={Register}/>
        <Route path='/' exact Component={Home}/>
        <Route path='/games' Component={Games}/>
        <Route path='/gains' Component={Gains}/>
        <Route path='/datas' Component={Datas}/>
        <Route path='/gain/:gainId' Component={EditGain}/>
        <Route path='/game/:gameId' Component={PageEditGame}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;