import { getUserCookie } from "../Cookies/Users";
import requestApi from "./request_api";

export async function getGains(game_id) {
    const res = await requestApi('/getGains', 'post', {
        user_token: getUserCookie(),
        game_id: game_id,
    });
    
    return res.data;
}

export async function  getAllGains() {
    const res = await requestApi('/getAllGains', 'post', {
        user_token: getUserCookie(),
    })
    
    return res.data;
}

export async function createGain(name, game_id){
    const res = await requestApi('/createGain', 'post', {
        user_token: getUserCookie(),
        input: {
            name: name,
            game_id: game_id,
        }
    });
    
    return res.data;
}

export async function getGainById(gain_id){
    const res = await requestApi('/getGainById', 'post', {
        user_token: getUserCookie(),
        gain_id: gain_id,
    });
    
    return res.data;
}

export async function updateGain(gain_id, name, qts, max_by_day, lundi, mardi, mercredi, jeudi, vendredi, samedi, dimanche, by_day_diff){
    const res = await requestApi('/updateGain', 'post', {
        user_token: getUserCookie(),
        input: {
            gain_id: gain_id,
            name: name,
            qts: qts,
            is_active: true,
            max_by_day: max_by_day,
            lundi: lundi,mardi: mardi,mercredi: mercredi,jeudi: jeudi, vendredi: vendredi, samedi: samedi, dimanche: dimanche,
            by_day_diff,
        }
    });

    return res.data;
}

export async function uploadImg(file, lang, gainId){
    const res = await requestApi('/uploadImg', 'post', {
        user_token: getUserCookie(),
        file: file,
        input: {
            lang: lang,
            gain_id: gainId,
        }
    });

    return res.data;
}

export async function deleteGain(gain_id){
    const res = await requestApi('/deleteGain', 'post', {
        user_token: getUserCookie(),
        input:{
            gain_id: gain_id,
        }
    });

    return res.data;
}