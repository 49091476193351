import { useParams } from "react-router-dom";
import NavBar from "../../NavBar/NavBar";
import NeedConnected from "../../NeedConnected/NeedConnected";
import { useEffect, useState } from "react";
import { getGainById } from "../../../utils/requests/Gains";
import { updateGain } from "../../../utils/requests/Gains";
import { uploadImg } from "../../../utils/requests/Gains";

export function EditGain(){
    const {gainId} = useParams();
    const [gain, setGain] = useState(null);
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState('');
    const [name, setName] = useState('');
    const [qts, setQts] = useState(0);
    const [max_by_day, setMaxByDay] = useState(0);
    const [lang, setLang] = useState('');
    const [file, setFile] = useState(null);
    const [lundi, setLundi] = useState(0);
    const [mardi, setMardi] = useState(0);
    const [mercredi, setMercredi] = useState(0);
    const [jeudi, setJeudi] = useState(0);
    const [vendredi, setVendredi] = useState(0);
    const [samedi, setSamedi] = useState(0);
    const [dimanche, setDimanche] = useState(0);
    const [by_day_diff, setByDayDiff] = useState(0);

    const loadGain = async () => {
        const res = await getGainById(gainId);
        if(res.success){
            setGain(res.data);
            setName(res.data.name);
            setQts(res.data.qts);
            setMaxByDay(res.data.max_by_day);
            setLundi(res.data.lundi);
            setMardi(res.data.mardi);
            setMercredi(res.data.mercredi);
            setJeudi(res.data.jeudi);
            setVendredi(res.data.vendredi);
            setSamedi(res.data.samedi);
            setDimanche(res.data.dimanche);
            setByDayDiff(res.data.by_day_diff);
        }else{
            setMsg(res.msg);
        }
        console.log(res);
    }

    const upload = async () =>{
        const res = await uploadImg(file, lang, gainId);
        if(res.success){
            setMsg('Image upload avec success');
        }else{
            setMsg(res.msg);
        }
    }

    useEffect(()=>{
        loadGain();
        setTimeout(()=>{
            setLoading(false);
        }, 300);
    },[]);

    return(
        <>
            <NeedConnected/>
            <NavBar/>
            <div className="container mt-5 p-4">
                {
                    (loading) ? 
                    <h1 className="text-center">
                        Chargement...
                    </h1>
                    :
                    (gain == null) ?
                    <div className="text-center">
                        <span className="err">{msg}</span>
                    </div>
                    :
                    <>
                        <div className="row">
                            <div className="col col-3">
                                <span>
                                    ID du gain :
                                </span>
                            </div>
                            <div className="col col-9">
                                <span>
                                    {gain.id}
                                </span>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col col-3">
                                <span>
                                    Nom du gain : 
                                </span>
                            </div>
                            <div className="col col-9">
                                <input type="text" className="form-control" value={name} onChange={(e)=>{setName(e.target.value)}} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Qts en stock
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setQts(e.target.value)}} type="number" className="form-control" value={qts} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Quantité par jours (0 pour sans limite)
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setMaxByDay(e.target.value)}} type="number" className="form-control" value={max_by_day} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Quantité différente dans la semaine
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input checked={by_day_diff} onChange={(e)=>{setByDayDiff(e.target.checked ? 1 : 0)}} type="checkbox"/>
                            </div>
                        </div><div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Lundi
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setLundi(e.target.value)}} type="number" className="form-control" value={lundi} />
                            </div>
                        </div><div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Mardi
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setMardi(e.target.value)}} type="number" className="form-control" value={mardi} />
                            </div>
                        </div><div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Mercredi
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setMercredi(e.target.value)}} type="number" className="form-control" value={mercredi} />
                            </div>
                        </div><div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Jeudi
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setJeudi(e.target.value)}} type="number" className="form-control" value={jeudi} />
                            </div>
                        </div><div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Vendredi
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setVendredi(e.target.value)}} type="number" className="form-control" value={vendredi} />
                            </div>
                        </div><div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Samedi
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setSamedi(e.target.value)}} type="number" className="form-control" value={samedi} />
                            </div>
                        </div><div className="row">
                            <div className="col col-3 mt-3">
                                <span>
                                    Dimanche
                                </span>
                            </div>
                            <div className="col col-9 mt-3">
                                <input onChange={(e)=>{setDimanche(e.target.value)}} type="number" className="form-control" value={dimanche} />
                            </div>
                        </div>
                        <label htmlFor="img" className="mt-3">
                            <input onChange={(e)=>{setFile(e.target.files[0])}} type="file" id="img" />
                        </label>
                        <select onChange={(e)=>{setLang(e.target.value)}}>
                            <option value="">-- Langue --</option>
                            <option value="fr">-- FR --</option>
                            <option value="nl">-- NL --</option>
                            <option value="en">-- EN --</option>
                        </select>
                        <button className="btn btn-success" onClick={()=>{upload()}}>
                            Publier
                        </button>
                        <button onClick={()=>{updateGain(gain.id, name, qts, max_by_day, lundi, mardi, mercredi, jeudi, vendredi, samedi, dimanche, by_day_diff)}} className="form-control mt-3">Mettre à jours</button>
                    </>
                }
            </div>
        </>
    )
}