import { useEffect, useState } from "react";
import { getAllTime } from "../../../utils/requests/Games";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend, TimeScale);

export default function Graph(){
    const [times, setTimes] = useState([]);
    const [loading, setLoading] = useState(true);

    const loadTimes = async () => {
        const res = await getAllTime();

        if(res.success){
            setTimes(res.data);
            setTimeout(()=>{
                setLoading(false);
            }, 300);    
        }
    }

    useEffect(()=>{
        loadTimes();    
    }, []);

    // Transform data to get counts per hour
  const countsPerHour = times.reduce((acc, item) => {
    const date = new Date(item.time_stamp);
    const hour = date.getHours() + 2;
    acc[hour] = (acc[hour] || 0) + 1;
    return acc;
  }, {});

    // Generate labels and data for the chart
    const labels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
    const chartData = labels.map((label, index) => countsPerHour[index] || 0);

    const chartConfig = {
        labels,
        datasets: [
        {
            label: 'Nombre d\'occurrences par heure',
            data: chartData,
            fill: false,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            tension: 0.1,
        },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Joueurs par heure',
        },
        },
        scales: {
        x: {
            title: {
            display: true,
            text: 'Heure',
            },
        },
        y: {
            title: {
            display: true,
            text: 'Nombre de joueurs',
            },
        },
        },
    };

    if(loading){
        return( 
            <h1 className="text-center">
                Chargement
            </h1>
        );
    }

    if(!loading && !times.length){
        return(
            <h1 className="text-center">
                Aucune données
            </h1>
        )
    }

    else{
        return (
            <Line style={{width: '100%'}} data={chartConfig} options={options}/>
        );
    }
}