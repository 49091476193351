import NeedConnected from "../../NeedConnected/NeedConnected"
import NavBar from "../../NavBar/NavBar"
import { useEffect, useState } from "react"
import { getAllGains, createGain } from "../../../utils/requests/Gains";
import { getGames } from "../../../utils/requests/Games";

export default function Gains(){

    const [gains, setGains] = useState([]);
    const [name, setName] = useState('');
    const [games, setGames] = useState([]);
    const [game_id, setGame_id] = useState('');
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState('');

    const loadAllGains = async () =>{
        const res = await getAllGains();
        if(res.success){
            setGains(res.data);
        }
    }

    const loadGames = async () => {
        const res = await getGames();
        if(res.success){
            setGames(res.data);
        }else{
            setMsg(res.msg);
        }
    }

    const submit = async (e) => {
        e.preventDefault();
        const res = await createGain(name, game_id);
        if(!res.success){
            setMsg(res.msg);
        }else{
            window.location.reload();
        }
    }

    useEffect(()=>{
        loadAllGains();
        loadGames();
        setTimeout(()=>{
            setLoading(false);
        }, 200);
    }, []);

    return(
        <>
        <NeedConnected></NeedConnected>
            <NavBar/>
            <div className="container p-4 mt-5">

                {
                    (loading) ?
                    <h1 className="text-center">
                        Chargement
                    </h1>
                    :
                    gains.map((gain)=>{
                        return(
                            <div key={gain.id} className="row mt-3 mb-3 c-hover" onClick={()=>{window.location.href = '/gain/'+gain.id}}>
                                <div className="col col-4">
                                    <span>
                                        {gain.id}
                                    </span>
                                </div>
                                <div className="col col-4">
                                    <span>
                                        {gain.name}
                                    </span>
                                </div>
                                <div className="col col-4">
                                    <span style={(gain.qts > 0 ? {color: 'green'} : {color: 'red'})}>
                                    qts : {gain.qts} {gain.max_by_day ? "("+gain.max_by_day+ ' par jours)' : 'illimité par jours'}
                                    </span>
                                </div>
                                <hr />
                            </div>
                        )
                    })
                }

                <form onSubmit={submit}>
                    <div className="row">
                        <div className="col col-4">
                            <input required value={name} onChange={(e)=>{setName(e.target.value)}} type="text" placeholder="Nom du gain" className="form-control" />
                        </div>
                        <div className="col col-4">
                            <select value={game_id} onChange={(e)=>{setGame_id(e.target.value)}} className="form-control" required>
                                <option value="">
                                    -- Selectionnez un jeu --
                                </option>
                                {
                                    games.map((game, index)=>{
                                        return(
                                            <option key={game.id} value={game.id}>{game.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col col-4">
                            <button onChange={(e)=>{setName(e.target.value)}} type="text" className="form-control">
                                Créer
                            </button>
                        </div>
                        <span className="err">{msg}</span>
                    </div>
                </form>
            </div>
        </>
    )
}