import NeedConnected from "../../NeedConnected/NeedConnected"
import NavBar from "../../NavBar/NavBar"
import { useEffect, useState } from "react"
import { getGames } from "../../../utils/requests/Games";
import FormAddGame from "./FormAddGame";
import EditGame from "./EditGame";

export default function Games(){

    const [games, setGames] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const loadGames = async () => {
        const res = await getGames();
        if(res.success){
            setGames(res.data);
        }
    }

    useEffect(()=>{
        loadGames();
        console.log(games);
        setTimeout(()=>{
            setIsLoading(false);
        }, 600);
    }, []);

    return(
        <>
        <NeedConnected></NeedConnected>
            <NavBar/>
            <div className="container p-4 mt-5">
                <div className="row">
                    {
                        isLoading ? 
                        (
                            <div className="text-center">
                                <h1>Chargement...</h1>
                            </div>
                        )
                        :
                        (
                            !isLoading && games.length === 0 ?
                            (
                                <div className="text-center">
                                    <h1>Aucun jeu trouvé</h1>
                                </div>
                            ) :
                            games.map((game)=>{
                                return(
                                    <EditGame key={game.id} game={game}/>
                                )
                            })
                        )
                    }
                </div>
                <FormAddGame/>
            </div>
        </>
    )
}