import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { deleteGame, getGame, updateGame } from "../../../utils/requests/Games";
import NavBar from "../../NavBar/NavBar";
import NeedConnected from "../../NeedConnected/NeedConnected";

export default function PageEditGame(){
    const {gameId} = useParams();

    const [game, setGame] = useState(null);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [msg, setMsg] = useState('');
    const [algo, setAlgo] = useState('random');
    const [ratio, setRatio] = useState(0);

    const loadGame = async () => {
        const res = await getGame(gameId);
        if(res.success){
            setGame(res.data);
            setName(res.data.name);
            setRatio(res.data.ratio);
            setAlgo(res.data.algo_type)
        }else{
            setMsg(res.msg);
        }
    }

    const updateGameSub = async () => {
        const res = await updateGame(name, game.default_gain_id, game.id, algo, ratio);
        if(!res.success){
            setMsg(res.msg)
        }
    }

    useEffect(()=>{
        loadGame();
        setTimeout(()=>{
            setLoading(false);
        }, 100);
    },[]);

    return(
        <>
        <NeedConnected/>
            <NavBar/>
            <div className="container p-4 mt-5">
                {
                    (loading) ? 
                    <h1 className="text-center">
                        Chargement
                    </h1>
                    :
                    <div>
                        {
                            (game) ?
                            <div className="row">
                                <div className="col col-3">
                                    <input className="form-control" value={name} type="text" onChange={(e)=>{setName(e.target.value);}} />
                                </div>
                                <div className="col col-3">
                                    <select onChange={(e)=>{setAlgo(e.target.value)}} value={algo} className="form-control">
                                        <option value="">-- Type d'algo --</option>
                                        <option value="ratio">Ratio</option>
                                        <option value="random">Random</option>
                                    </select>
                                </div>
                                <div className="col col-3">
                                    <input style={(algo == 'ratio') ? {border: 'green solid 2px'} : {border: 'brown solid 2px'}} onChange={(e)=>{setRatio(e.target.value)}} type="number" placeholder="Ratio" value={ratio} className="form-control" />
                                </div>
                                <div className="col col-3">
                                    <button className="form-control" onClick={()=>{updateGameSub()}}>Modifier</button>
                                </div>
                                <div className="col col-12">
                                    <button onClick={()=>{deleteGame(game.id); window.location.href = '/';}} className="btn mt-3 btn-danger form-control">!!! Supprimer !!!</button>
                                </div>
                            </div>
                           :
                        ''
                        }
                        <span className="err">{msg}</span>
                    </div>
                }
            </div>
        </>
    );
}