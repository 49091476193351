import requestApi from "./request_api";
import { key } from "../config";

export default async function play(game_id, player_data, lang){
    const res = await requestApi('/play', 'post', {
        token: key,
        input: {
            game_id: game_id,
            player_data: player_data,
            lang: lang,
        }
    });

    return res.data;
}