import { useState } from "react"
import { createGame } from "../../../utils/requests/Games";

export default function FormAddGame() {

    const [name, setName] = useState('');
    const [err, setErr] = useState('');

    const submit = async (e) => {
        e.preventDefault();
        const res = await createGame(name);
        if(!res.success){
            setErr(res.msg);
        }else{
            window.location.reload();
        }
    }

    return(
        <form onSubmit={submit}>
            <div className="row">
                <div className="col col-10">
                    <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} className=" form-control" placeholder="Nom du projet" />
                </div>
                <div className="col col-2">
                    <button className="form-control">Créer</button>
                </div>
            </div>
            <span className="err">
                {err}
            </span>
        </form>
    )
}