import NeedConnected from "../../NeedConnected/NeedConnected"
import NavBar from "../../NavBar/NavBar"
import { useEffect, useState } from "react"
import play from "../../../utils/requests/Play";
import { getGames, removePlayersForGame, getDatas } from "../../../utils/requests/Games";

export default function Datas(){

    const [game_id, setGame_id] = useState('');
    const [games, setGames] = useState([]);
    const [result, setResult] = useState([]);

    const playGame = async () => {
        const res = await play(game_id, {
            nom: 'Julien',
            prenom: 'Jacobs',
            age: 25,
            ville: 'Braine-le-château',
            email: 'Julien.j.1999@hotmail.com',
        }, 'fr');

        if(res.success){
            setResult(res.data);
        }
    }

    const dlData = async () =>{
        const res = await getDatas(game_id);
        if(res.success){
            downloadFile(res.data, 'datas.csv');
        }
    }

    const loadGames = async () => {
        const res = await getGames();
        if(res.success){
            setGames(res.data);
        }
    }

    useEffect(()=>{
        loadGames();
    },[]);

    return(
        <>
        <NeedConnected></NeedConnected>
            <NavBar/>
            <div className="container p-4 mt-5">
                <div className="row">
                    <select className="form-control" value={game_id} onChange={(e)=>{setGame_id(e.target.value)}} required>
                        <option value="">Sélectionnez un jeu</option>
                        {
                            games.map((game)=>{
                                return(
                                    <option value={game.id}>{game.name}</option>
                                )
                            })
                        }
                    </select>
                    <div className="col col-6">
                        <button onClick={()=>{playGame()}} className="form-control mt-3 btn-warning btn">Play!</button>
                    </div>
                    <div className=" col col-6">
                        <button onClick={()=>{dlData()}} className="form-control mt-3 btn btn-success">Télécharger!</button>
                    </div>
                </div>
                <h1 className="mt-5 text-center">Résultat : </h1>
                    <div className="row text-center">
                    {Object.entries(result).map(([key, value]) => (
                        (key !== 'uri') ?
                        <div key={key} className="row">
                            <div className="col col-4">{key}</div>
                            <div className="col col-8">{value}</div>
                        </div>
                        :
                        (value)?
                        <div>
                            <img className="mt-5" src={value} alt="Image du gain" />
                        </div>
                        :
                        <div>
                            <span className="err">Aucune image trouvée pour ce gain</span>
                        </div>    
                    ))}
                </div>
                <button className="btn btn-danger" onClick={()=>{removePlayersForGame(game_id)}}>Reset le jeu</button>
            </div>
        </>
    )
}

function downloadFile(uri, fileName) {
    const link = document.createElement('a');
    link.href = uri;
    link.setAttribute('download', fileName); // Spécifie le nom du fichier
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}