import { getUserCookie, setUserCookies } from "../Cookies/Users";
import requestApi from "./request_api";

export async function GetUserInfos(){
    const res = await requestApi('/getUserSession', 'post', {
        user_token: getUserCookie(),
    });
    
    return res.data;
}

export async function login(email, password){
    const res = await requestApi('/login', 'post', {
        input:{
            email,
            password,
        }
    });

    setUserCookies(res.data.data);
    return res.data;
}

export async function register(email, password){
    const res = await requestApi('/register', 'post', {
        input:{
            email,
            password,
            birthday: '24-02-1999',
            tel: '',
        }
    });
    
    setUserCookies(res.data.data);
    return res.data;
}